import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Bench Press 12-12-12`}</p>
    <p>{`DB Rows 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`21-Pullups`}</p>
    <p>{`15-HSPU’s`}</p>
    <p>{`9-DB Thrusters (35/25’s)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On October 28th The Ville will be hosting a fundraising wod for the
family of LMPD Officer Jason Schweitzer who was tragically killed by a
drunk driver in Lexington, Ky.  You can donate and purchase a shirt at
the link below.  CrossFit 222 will show up as the host but you can
choose your shirt size and our location when checking out.  The shirts
will be brought to The Ville for you.  Please sign up soon and show your
support for one of our LMPD officers and his family!  Sign up by the
18th to ensure you get a shirt.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.
Truegritcomp.wordpress.com.  Workout 1 is posted!  If you’d like to be
on a team but don’t have teammates please email Daniel.  You can also
add your name `}<strong parentName="p">{`**`}</strong>{`to the board in back at the Ville if you’d like to
help judge or volunteer. ***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      